<template>
  <svg
    width="140"
    viewBox="0 0 140 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M120.651 20.3163C120.651 19.5826 121.056 18.7125 121.528 18.3806C123.416 17.0531 127.812 17.9053 127.812 20.3163C127.812 22.5174 123.569 23.6242 121.632 22.286C121.114 21.9272 120.651 21.1612 120.651 20.3163ZM118.86 12.3657C118.86 13.32 119.587 14.0681 120.511 14.0681C120.92 14.0681 124.175 11.6194 127.018 13.4653C127.899 14.0358 127.81 14.3982 127.81 15.7723C125.309 14.0465 121.268 14.2959 119.234 16.1598C116.984 18.2228 117.176 22.7004 119.267 24.4351C121.737 26.4855 125.354 26.5053 127.947 24.7149C128.118 25.3733 128.784 25.8935 129.533 25.7751C130.222 25.6657 130.84 25.0289 130.84 24.2898V14.3515C130.84 13.6052 130.455 12.7962 130.154 12.3621C127.062 7.89343 118.86 9.78959 118.86 12.3657Z"
      fill="#272B3F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M89.9414 17.9008C89.9414 12.7092 95.6823 10.6534 97.8871 15.6727C100.099 20.7065 93.7402 25.5644 90.7384 20.6293C90.3416 19.9781 89.9431 18.9108 89.9431 17.899L89.9414 17.9008ZM98.3413 11.3709C97.9532 11.2633 97.7914 11.0588 97.4973 10.822C95.7223 9.39944 93.1729 9.41917 91.2256 10.422C87.2789 12.4527 85.4691 18.5843 88.8955 23.0959C90.7192 25.4944 94.0239 26.5151 96.7856 25.098C97.4991 24.732 97.7653 24.4037 98.3413 24.0055C98.39 26.2514 101.096 25.8245 101.096 24.1472V7.39564C101.096 5.65913 98.317 4.89851 98.3361 7.81541C98.3431 8.99939 98.3413 10.1852 98.3413 11.3709Z"
      fill="#272B3F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M111.424 15.7722H106.329V9.09888H111.424C115.119 9.09888 115.266 15.7722 111.424 15.7722ZM103.299 7.82161V23.7211C103.299 26.7797 106.329 26.0747 106.329 24.4314V19.0371C109.941 19.0371 113.183 19.5753 115.456 17.2325C119.253 13.3164 117.158 5.83575 110.872 5.83575H105.227C104.021 5.83575 103.299 6.58202 103.299 7.82341V7.82161Z"
      fill="#272B3F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M79.7508 13.2178C82.4272 13.2178 82.9023 14.6744 83.7445 16.4827H76.4462C76.5627 15.0386 78.1376 13.2178 79.7525 13.2178H79.7508ZM73.5539 17.1931C73.5539 19.5951 73.6566 20.9118 75.0105 22.9318C77.5982 26.7959 82.4324 26.394 84.5937 23.6045C85.3873 22.582 86.1025 21.7083 85.2933 20.5674C84.0717 18.8453 82.6273 21.5541 82.5473 21.6366C80.1301 24.1337 76.648 21.9433 76.4479 19.4659C77.8661 19.4659 85.2916 19.7279 85.9859 19.2202C86.4645 18.8704 86.6802 18.3214 86.588 17.5429C86.3339 15.4009 85.8502 13.7021 84.4058 12.1145C81.7276 9.17428 77.0744 9.52947 74.7651 13.3308C74.2796 14.1309 73.5557 15.9624 73.5557 17.1949L73.5539 17.1931Z"
      fill="#272B3F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48.7648 15.3471C48.7648 18.4308 49.5096 20.8849 51.3455 22.7667C54.4326 25.9311 58.9345 26.7258 62.481 24.5175C66.7584 21.8536 64.1185 20.3162 63.7757 20.3162C62.0077 20.3162 63.0205 20.5315 60.9653 21.8213C55.073 25.515 48.6238 17.4782 53.3641 11.1475C54.9755 8.99662 58.2802 8.19833 60.9653 9.87026C62.3749 10.7493 62.5906 11.5171 63.3615 11.5171C64.517 11.5171 66.6383 9.73751 62.2026 7.03408C58.7065 4.90292 54.4326 5.74606 51.5526 8.71319C50.585 9.7106 50.3309 10.1411 49.6801 11.4686C49.1528 12.5468 48.763 13.7971 48.763 15.3507L48.7648 15.3471Z"
      fill="#272B3F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.805 7.82344V23.8646C131.805 26.4586 134.835 26.1482 134.835 24.4333V7.25656C134.835 5.47341 131.805 5.16486 131.805 7.82523V7.82344Z"
      fill="#272B3F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M68.871 11.9403C68.5143 10.5608 66.1163 11.0577 66.1163 12.509V24.4331C66.1163 25.8772 68.4012 26.2916 68.9963 24.8475C69.4645 23.7084 68.7858 16.7624 69.3183 15.5264C70.3955 13.031 72.5272 14.3836 73.6009 13.8383C74.6781 13.2929 75.1584 10.9465 71.7615 10.9465C70.3415 10.9465 69.7133 11.3573 68.8693 11.9403H68.871Z"
      fill="#272B3F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M135.799 24.2914C135.799 26.5284 139.518 27.1402 139.518 24.008C139.518 21.7799 135.799 21.7082 135.799 24.2914Z"
      fill="#272B3F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 15.921C0 20.0523 1.69147 24.3828 4.40618 27.158C9.719 32.59 17.4577 33.5551 23.8598 29.5278C24.4289 29.1708 25.3512 28.4604 25.7984 28.0047C29.6269 24.094 28.9725 23.0787 28.9725 17.4709V10.7079C28.9725 8.54799 29.6477 7.25458 30.5909 6.03651C31.7899 4.49016 33.6241 3.9466 35.6706 3.9466C40.6423 3.9466 43.7729 10.7097 39.8088 14.9756C38.4131 16.4771 36.0012 17.2969 33.8138 16.7067C33.1055 16.5147 33.1038 16.3443 32.2528 16.3443C30.5231 16.3443 29.5277 19.2433 31.8578 20.1313C35.5018 21.5198 39.0466 20.5493 41.937 18.1544C48.4367 12.7709 45.2069 0 35.2599 0C33.5841 0 31.6768 0.581227 30.5683 1.22165C28.0555 2.67472 26.1291 5.07856 25.5009 8.11386C25.118 9.96159 25.2816 17.5534 25.2833 20.0057C25.2851 22.9477 24.6917 24.4044 22.3337 25.9812C17.6839 29.0901 11.3826 28.8999 7.48285 24.8313C6.54662 23.8554 5.6104 22.7414 5.00829 21.4659C3.10103 17.4225 3.46821 12.6507 5.9567 8.95879C6.22295 8.56234 6.3204 8.52467 6.57969 8.19279C6.8442 7.85554 6.88944 7.76046 7.2079 7.43038C8.74275 5.83739 11.7533 3.9466 14.7604 3.9466C16.3857 3.9466 17.0226 3.87126 18.6462 4.30897C19.8626 4.63726 19.7025 4.93326 21.1834 4.93326C22.4346 4.93326 22.687 3.65779 22.687 2.6801C22.687 0.764206 17.7692 0.00358782 15.58 0.00358782C12.954 0.00358782 11.1129 0.633251 9.27177 1.39028C8.34947 1.77059 7.58552 2.25136 6.80417 2.79133C6.39349 3.07476 6.09765 3.3295 5.72003 3.64702C5.5025 3.83 5.43464 3.93225 5.22581 4.1242C3.99723 5.25257 2.95138 6.44552 2.14044 7.98649C1.85679 8.52646 1.67059 8.74711 1.42522 9.36242C0.643873 11.3178 0.0017402 13.2211 0.0017402 15.9246L0 15.921Z"
      fill="#272B3F"
    />
  </svg>
</template>
